import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Admin from 'pages/Settings/Admin';
import { useSelector } from 'react-redux';
import { isBrand } from 'hooks/useBrandType';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import { useTranslation } from 'react-i18next';
import Business from 'pages/Settings/Business';
import Account from 'redesign/AccountTab/Account';
import Events from 'pages/Settings/Events/Events';
import UsersRoles from 'pages/Settings/UsersRoles';
import { Switch, Redirect } from 'react-router-dom';
import BillingInfo from 'pages/Settings/BillingInfo';
import usePermissionPlan from 'hooks/permissionPlan';
import Advanced from 'pages/Settings/Advanced/index';
import { Box, Tabs, Tab, Paper } from '@mui/material';
import ProtectedRoute from 'components/ProtectedRoute';
import Printers from 'pages/Settings/Printers/Printers';
import Directory from 'pages/Settings/Directory/Directory';
import EmployeesAndJobs from 'pages/Settings/EmployeesAndJobs';
import BrandLocations from 'pages/Settings/BrandLocations/index';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import LanguageSettings from 'pages/Settings/LanguageSettings/LanguageSettings';

const excludeRoutes = ['/settings/printers', '/settings/events', '/settings/billing'];
const excludeRoutesForSingle = ['/settings/billing'];
const excludeRoutesForBrand = [
  '/settings/business',
  '/settings/billing',
  '/settings/printers',
  '/settings/events',
  '/settings/brands',
];

const routes = [
  { id: 'SETTINGS_BUSINESS', plan: '', path: '/business', name: 'settings.business' },
  { id: 'SETTINGS_USERS', plan: '', path: '/usersAndRoles', name: 'settings_routes.users_and_roles' },
  { id: 'SETTINGS_USERS', plan: '', path: '/employeesAndJobs', name: 'settings_routes.employeesAndJobs' },
  // { id: 'SETTINGS_WEBSITE', plan: 'WEB_BUNDLE', path: '/website', name: 'Website' },
  { id: 'SETTINGS_BILLING', plan: '', path: '/billing', name: 'settings_routes.billing' },
  { id: 'SETTINGS_PRINTERS', plan: '', path: '/printers', name: 'settings_routes.printer' },
  { id: 'SETTINGS_EVENTS', plan: '', path: '/events', name: 'settings_routes.events_notifications' },
  { id: 'SETTINGS_BRAND_LOCATIONS', plan: '', path: '/brands', name: 'settings_routes.brands_locations' },
  { id: 'SETTINGS_ACCOUNT', plan: '', path: '/account', name: 'settings.account' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/advanced', name: 'settings_routes.advanced' },
  // { id: 'SETTINGS_LANGUAGE', plan: '', path: '/language', name: 'settings_routes.language' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/admin', name: 'settings_routes.admin' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/marketing', name: 'settings_routes.marketing' },
];

const routesForTypeOfIsNotSingle = [
  { id: 'SETTINGS_BUSINESS', plan: '', path: '/business', name: 'settings.business' },
  { id: 'SETTINGS_USERS', plan: '', path: '/usersAndRoles', name: 'settings_routes.users_and_roles' },
  { id: 'SETTINGS_USERS', plan: '', path: '/employeesAndJobs', name: 'Employees and Jobs' },
  { id: 'SETTINGS_BILLING', plan: '', path: '/billing', name: 'settings_routes.billing' },
  { id: 'SETTINGS_BRAND_LOCATIONS', plan: '', path: '/brands', name: 'settings_routes.brands_locations' },
  { id: 'SETTINGS_ACCOUNT', plan: '', path: '/account', name: 'settings.account' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/advanced', name: 'settings_routes.advanced' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/admin', name: 'settings_routes.admin' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/marketing', name: 'settings_routes.marketing' },
];
const routesForTypeOfBrand = [
  { id: 'SETTINGS_USERS', plan: '', path: '/usersAndRoles', name: 'settings_routes.users_and_roles' },
  { id: 'SETTINGS_USERS', plan: '', path: '/employeesAndJobs', name: 'Employees and Jobs' },
  { id: 'SETTINGS_ACCOUNT', plan: '', path: '/account', name: 'settings.account' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/advanced', name: 'settings_routes.advanced' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/admin', name: 'settings_routes.admin' },
  { id: 'SETTINGS_ADMIN', plan: '', path: '/marketing', name: 'settings_routes.marketing' },
];

const Settings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isTypeBrand } = isBrand();
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { currentBusiness } = useSelector(({ business }) => business);
  const currentTab = localStorage.getItem('currentTab');
  localStorage.removeItem('redirectUrl');
  const filteredRoutes = useMemo(() => {
    if (isTypeSingle) {
      const tabs = routes.filter(({ plan, id }) => {
        return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
      });
      tabs.forEach((tab) => {
        if (tab.path === currentTab) {
          return history.push(`/settings${currentTab}${history.location.search}`);
        } else if (history.location.pathname.includes(tab.path)) {
          localStorage.setItem('currentTab', tab.path);
          return history.push(`/settings${tab.path}${history.location.search}`);
        } else {
          const currentTabAfterSet = localStorage.getItem('currentTab');
          if (excludeRoutesForSingle.includes(currentTabAfterSet)) {
            return history.push('/settings/business');
          }
        }
      });
      const currentTabAfterSet = localStorage.getItem('currentTab');
      if (!currentTabAfterSet) history.push('/settings/business');
      if (tabs.length === 2) {
        if (currentTabAfterSet) {
          history.push(`/settings${currentTabAfterSet}`);
          return tabs;
        } else {
          history.push('/settings/printers');
          return tabs;
        }
      }
      return tabs;
    } else if (isTypeBrand) {
      let singleTab = 1;
      const tabs = routesForTypeOfBrand.filter(({ plan, id }) => {
        return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
      });
      if (tabs.length === singleTab) {
        history.push('/settings/account');
      } else {
        if (excludeRoutesForBrand.includes(history.location.pathname)) {
          history.push('/settings/usersAndRoles');
        }
      }
      return [
        ...routesForTypeOfBrand.filter(({ plan, id }) => {
          return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
        }),
      ];
    } else {
      let singleTab = 1;
      const tabs = routesForTypeOfIsNotSingle.filter(({ plan, id }) => {
        return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
      });
      if (tabs.length === singleTab) {
        history.push(`/settings${tabs[0].path}`);
      } else if (excludeRoutes.includes(history.location.pathname)) {
        history.push('/settings/business');
      }
      return [
        ...routesForTypeOfIsNotSingle.filter(({ plan, id }) => {
          return checkPermission(all[id]) && checkPermissionPlan(all[plan]);
        }),
      ];
    }
  }, [checkPermission, checkPermissionPlan, isTypeSingle, isTypeBrand, currentBusiness]);

  const [value, setValue] = useState(history.location.pathname);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    history.push(newValue);
    if (!history.location.pathname.includes(currentTab)) {
      localStorage.setItem('currentTab', newValue.slice(9, newValue.length));
    }
  };

  useEffect(() => {
    localStorage.setItem('currentTab', history.location.pathname);
    setValue(history.location.pathname);
  }, [history.location]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('currentTab');
    };
  }, []);

  return (
    <Paper sx={{ pb: 2, backgroundColor: '#F7F8F9' }} className={'settings-wrapper'}>
      <Box>
        <Box sx={{ width: '100%' }}>
          <Tabs
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            variant="scrollable"
            scrollButtons="auto"
            selectionFollowsFocus
            value={value}
            onChange={handleChange}
          >
            {filteredRoutes.map(({ id, path, name }, index) => (
              <Tab key={`${id}_${index}` || path} value={`/settings${path}`} label={t(name)} />
            ))}
          </Tabs>
        </Box>
      </Box>

      <Box sx={{ padding: '24px' }}>
        <ErrorBoundaryWrapper>
          <Switch>
            <ProtectedRoute exact path="/settings/account" component={Account} permission="SETTINGS_ACCOUNT" />
            {/* <ProtectedRoute exact component={Website} path="/settings/website" permission="SETTINGS_WEBSITE" /> */}
            <ProtectedRoute exact component={Business} path="/settings/business" permission="SETTINGS_BUSINESS" />
            <ProtectedRoute exact component={BillingInfo} path="/settings/billing" permission="SETTINGS_BILLING" />
            <ProtectedRoute exact component={UsersRoles} path="/settings/usersAndRoles" permission="SETTINGS_USERS" />
            <ProtectedRoute
              exact
              component={EmployeesAndJobs}
              path="/settings/employeesAndJobs"
              permission="SETTINGS_USERS"
            />
            <ProtectedRoute exact component={Directory} path="/settings/marketing" permission="SETTINGS_ADMIN" />
            <ProtectedRoute exact component={Admin} path="/settings/admin" permission="SETTINGS_ADMIN" />
            <ProtectedRoute exact component={Printers} path="/settings/printers" permission="SETTINGS_PRINTERS" />
            <ProtectedRoute exact component={Events} path="/settings/events" permission="SETTINGS_EVENTS" />
            <ProtectedRoute
              exact
              component={LanguageSettings}
              path="/settings/language"
              permission="SETTINGS_BUSINESS"
            />
            <ProtectedRoute exact component={Advanced} path="/settings/advanced" permission="SETTINGS_BUSINESS" />

            <ProtectedRoute
              exact
              component={BrandLocations}
              path="/settings/brands"
              permission="SETTINGS_BRAND_LOCATIONS"
            />
            <Redirect to={`/settings${filteredRoutes?.[0]?.path}`} />
          </Switch>
        </ErrorBoundaryWrapper>
      </Box>
    </Paper>
  );
};

export default Settings;
