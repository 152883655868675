import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UserSignup from 'pages/Auth/UserSignup';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store';
import { ClearCacheProvider } from 'react-clear-cache';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { SocketProvider } from 'providers/Socket';
import Home from 'pages/Home';
import theme from './Theme/index';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthorizationStepsWrapper from 'pages/InvitationLocation/Authorization/AuthorizationStepsWrapper';
import { PauseResumeProvider } from 'context/pauseResumeContext';
import { PaymentPeriodicityProvider } from 'context/paymentPeriodicityContext';
import { SubscriptionConfigProvider } from 'context/subscriptionPlanConfigContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_s9JBJsgJuCpTyuvM457NhmTU00Axogybz7');
ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <ClearCacheProvider duration={5000}>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <SocketProvider>
              <PaymentPeriodicityProvider>
                <SubscriptionConfigProvider>
                  <PauseResumeProvider>
                    <Router>
                      <Switch>
                        <Route exact path="/reports/:uuid" component={Home} />
                        <Route exact path="/location/invitation/:id" component={AuthorizationStepsWrapper} />
                        <Route exact path="/complete-signup/:token" component={UserSignup} />
                        <Route path="/" component={App} />
                      </Switch>
                    </Router>
                  </PauseResumeProvider>
                </SubscriptionConfigProvider>
              </PaymentPeriodicityProvider>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                pauseOnHover
              />
            </SocketProvider>
          </Provider>
        </Elements>
      </ClearCacheProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
