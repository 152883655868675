import React, { useMemo, useState, useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';
import Settings from './Settings';
import ProtectedRoute from 'components/ProtectedRoute';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import Coupons from './Coupons';
import { useTranslation } from 'react-i18next';
import Rewards from './Rewards';

const routes = [
  { id: 'SETTINGS', plan: '', path: '/website/main', name: '_main' },
  { id: 'COUPONS', plan: '', path: '/website/coupons', name: 'coupons_' },
  { id: 'REWARDS', plan: '', path: '/website/rewards', name: 'Rewards' },
];

const WebsiteSettings = () => {
  const { t } = useTranslation();
  localStorage.removeItem('redirectUrl');
  // const checkPermissionPlan = usePermissionPlan();
  // const checkPermission = usePermission();
  const history = useHistory();
  const [value, setValue] = useState(history.location.pathname);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    history.push(newValue);
    localStorage.setItem('currentTab', newValue.slice(9, newValue.length));
  };

  useEffect(() => {
    setValue(history.location.pathname);
  }, [history.location]);

  // const filteredRoutes = useMemo(
  //   () => routes.filter(({ plan, id }) => checkPermission(all[id]) && checkPermissionPlan(all[plan])),
  //   [checkPermission, checkPermissionPlan]
  // );

  return (
    <ErrorBoundaryWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ pb: 2 }}>
            <Tabs
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
              variant="scrollable"
              scrollButtons="auto"
              selectionFollowsFocus
              value={value}
              onChange={handleChange}
            >
              {routes.map(({ id, path, name }, index) => (
                <Tab key={id || path} value={`${path}`} label={t(name)} />
              ))}
            </Tabs>
            <Box sx={{ p: 3 }}>
              <Switch>
                <ProtectedRoute exact path="/website/main" component={Settings} permission="SETTINGS_ACCOUNT" />
                <ProtectedRoute exact path="/website/coupons" component={Coupons} permission="SETTINGS_ACCOUNT" />
                <ProtectedRoute exact path="/website/rewards" component={Rewards} permission="SETTINGS_ACCOUNT" />
                <Redirect
                  to={{
                    pathname: '/website/main',
                    state: { title: 'Website' },
                  }}
                />
              </Switch>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </ErrorBoundaryWrapper>
  );
};

export default WebsiteSettings;
