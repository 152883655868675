import { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { PAUSE_RESUME_STATUSES, PAUSE_RESUME_STATUSES_LABEL } from '../../constants/resumePause';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getProvidersStatuses } from 'redux/actions/providers';

const useCountDown = (statuses, callback) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { paused_until, status } = statuses;
  const [pauseTimer, handlePauseTimer] = useState(paused_until);

  useEffect(() => {
    handlePauseTimer(paused_until);
  }, [paused_until]);

  useEffect(() => {
    let timerMs = paused_until ? paused_until * 1000 - Date.now() : 1000;
    let interval;
    if (paused_until) {
      interval = setInterval(() => {
        timerMs -= 1000;
        let duration = moment.duration(timerMs, 'milliseconds');

        handlePauseTimer(duration);
      }, 1000);
      if (pauseTimer._milliseconds <= 0) {
        clearInterval(interval);
        dispatch(getProvidersStatuses());
        callback();
      }
    }

    return () => clearInterval(interval);
  }, [pauseTimer, paused_until, callback, status]);

  const duration = useMemo(() => {
    let dur = pauseTimer;
    if (!dur && status !== PAUSE_RESUME_STATUSES.RECEIVING) return '';
    if (status === PAUSE_RESUME_STATUSES.RECEIVING) return t(PAUSE_RESUME_STATUSES_LABEL.RECEIVING);
    if (dur > 700000000) {
      return t('store_status_notification.permanently_paused_title');
    } else if (status === PAUSE_RESUME_STATUSES.BUSY) {
      const result = `${t(PAUSE_RESUME_STATUSES_LABEL.BUSY)} ${
        dur?.hours() > 0 ? `${dur?.hours().toString().padStart(2, 0)} :` : ''
      } ${dur.minutes().toString().padStart(2, 0)} : ${dur.seconds().toString().padStart(2, 0)}`;
      return result;
    } else {
      const result = `${t(PAUSE_RESUME_STATUSES_LABEL.PAUSED)} ${
        dur?.hours() > 0 ? `${dur?.hours().toString().padStart(2, 0)} :` : ''
      } ${dur.minutes().toString().padStart(2, 0)} : ${dur.seconds().toString().padStart(2, 0)}`;
      return result;
    }
  }, [pauseTimer, status, t]);
  return { duration };
};

export default useCountDown;
