import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProviderSettingsWrapper } from 'pages/Providers/styles';
import { FormGroup, Switch, FormControlLabel } from '@mui/material';

const ProviderAutoConfirmSettings = ({
  isDisableAutoConfirm,
  handleOpenAutoConfirmModal,
  id,
  checked,
  handleConfirmOrderChange,
  type,
}) => {
  const { t } = useTranslation();
  const styles = {
    width: 'max-content',
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: isDisableAutoConfirm ? '#2c405a' : '#93c2fd',
      opacity: 1,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: isDisableAutoConfirm ? '#a8a8a8' : '#93c2fd',
      opacity: 1,
    },
  };

  return (
    <ProviderSettingsWrapper
      sx={{
        padding: '16px',
      }}
    >
      <FormGroup id={`${id}-manual`} onClick={isDisableAutoConfirm ? handleOpenAutoConfirmModal : () => {}}>
        <FormControlLabel
          sx={styles}
          disabled={isDisableAutoConfirm}
          control={
            <Switch checked={checked} disabled={false} onChange={(event) => handleConfirmOrderChange(event, type)} />
          }
          label={t(`Auto-confirm ${type} order`)}
        />
      </FormGroup>
    </ProviderSettingsWrapper>
  );
};
export default ProviderAutoConfirmSettings;
