import { useState } from 'react';

export const useProviderStatusHover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    const currentTarget = event.currentTarget; // Capture the value here
    setTimeout(() => {
      setAnchorEl(currentTarget);
    }, 500);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  return {
    anchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isOpen,
    id,
  };
};
