import React, { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import useCountDown from './useCountDown';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { PauseResumeContext } from 'context/pauseResumeContext';
import { PAUSE_RESUME_STATUSES, PAUSE_RESUME_STATUSES_LABEL } from 'constants/resumePause';
import { updateStopResumeModalTitle, updateStopResumeOrders } from 'redux/actions/providers';

function ResumePauseButton({ hasConnectProvider, modalTitle = '', providerAll = 'all', isOpen = false, id = '' }) {
  const dispatch = useDispatch();
  const { isSameStatusProvider } = useContext(PauseResumeContext);
  const { t } = useTranslation();
  const { stopResumeOrders } = useSelector(({ providers }) => providers);
  const { isBrandLoading } = useSelector(({ business }) => business);
  const { statuses } = stopResumeOrders;
  const { status } = statuses;

  const { duration } = useCountDown(statuses, () => {
    return dispatch(
      updateStopResumeOrders({
        ...stopResumeOrders,
        data: {
          ...stopResumeOrders.data,
          action: PAUSE_RESUME_STATUSES.RECEIVING,
        },
        statuses: {
          ...stopResumeOrders.statuses,
          paused_until: '',
          status: PAUSE_RESUME_STATUSES.RECEIVING,
        },
      })
    );
  });

  const partiallyAccepting = useMemo(() => {
    if (
      isSameStatusProvider?.length > 1 &&
      isSameStatusProvider.includes(PAUSE_RESUME_STATUSES.RECEIVING) &&
      isSameStatusProvider.includes(PAUSE_RESUME_STATUSES.BUSY) &&
      !isSameStatusProvider.includes(PAUSE_RESUME_STATUSES.PAUSED)
    ) {
      return {
        backgroundColor: 'linear-gradient(to right, #FF8A00 50%, rgba(255, 255, 255, 1) 50%)',
        borderColor: '#FF8A00',
        statusName: PAUSE_RESUME_STATUSES_LABEL.RECEIVING,
      };
    } else if (
      isSameStatusProvider?.length > 1 &&
      isSameStatusProvider.includes(PAUSE_RESUME_STATUSES.PAUSED) &&
      isSameStatusProvider.includes(PAUSE_RESUME_STATUSES.BUSY)
    ) {
      return {
        backgroundColor: 'linear-gradient(to right, #FF8A00 50%, rgba(255, 255, 255, 1) 50%)',
        borderColor: '#FF8A00',
        statusName: 'PARTIALLY ACCEPTING',
      };
    } else if (
      isSameStatusProvider?.length > 1 &&
      isSameStatusProvider.includes(PAUSE_RESUME_STATUSES.PAUSED) &&
      isSameStatusProvider.includes(PAUSE_RESUME_STATUSES.RECEIVING)
    ) {
      return {
        backgroundColor: 'linear-gradient(to right, #FF8A00 50%, rgba(255, 255, 255, 1) 50%)',
        borderColor: '#FF8A00',
        statusName: 'PARTIALLY ACCEPTING',
      };
    }
  }, [isSameStatusProvider]);

  const iconStyle = useMemo(() => {
    if (status === PAUSE_RESUME_STATUSES.RECEIVING) return { backgroundColor: '#11AF22' };
    if (status === PAUSE_RESUME_STATUSES.PARTIAL || status === PAUSE_RESUME_STATUSES.BUSY)
      return {
        backgroundColor: '#F2AE48',
      };
    return {
      backgroundColor: 'red',
    };
  }, [status]);

  const handleShowResumePause = () => {
    if (!duration) return;
    if (isOpen) {
      dispatch(updateStopResumeOrders({ showModal: true, provider: providerAll }));
      dispatch(updateStopResumeModalTitle(modalTitle));
    }
  };

  if (!hasConnectProvider && !stopResumeOrders?.loading && !isBrandLoading) {
    return (
      <LoadingButton
        id="resumePauseButton"
        size="small"
        className="mr-2"
        variant="outlined"
        sx={{
          color: 'black',
          textTransform: 'none',
          mb: 2,
          height: '40px',
          width: '200px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }}
        disabled={true}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>N/A</Typography>
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      id="resumePauseButton"
      size="small"
      className="mr-2"
      aria-describedby={id}
      onClick={handleShowResumePause}
      variant="outlined"
      loading={stopResumeOrders.loading || isBrandLoading}
      startIcon={
        !isBrandLoading &&
        !stopResumeOrders.loading &&
        (partiallyAccepting ? (
          <Box
            sx={{
              background: partiallyAccepting?.backgroundColor,
              borderColor: partiallyAccepting?.borderColor,
              border: `2px solid ${partiallyAccepting?.borderColor}`,
              mr: 1,
              width: 12,
              height: 12,
              borderRadius: 90,
            }}
          />
        ) : (
          <Box sx={{ ...iconStyle, mr: 1, width: 12, height: 12, borderRadius: 90 }} />
        ))
      }
      sx={{
        color: 'black',
        textTransform: 'none',
        mb: 2,
        height: '40px',
        width: '240px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      }}
    >
      {isSameStatusProvider?.length > 1 ? (
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>{t(`${partiallyAccepting?.statusName}`)}</Typography>
      ) : (
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          {PAUSE_RESUME_STATUSES.RECEIVING === isSameStatusProvider[0]
            ? t(`${PAUSE_RESUME_STATUSES_LABEL.RECEIVING}`)
            : isSameStatusProvider[0]}
        </Typography>
      )}
    </LoadingButton>
  );
}

export default ResumePauseButton;
