import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from 'assets/icons/TableDelete.png';
import { confirm } from 'components/MenuNew/ConfirmModal';
import JobsCheckMark from 'assets/icons/JobsCheckMark.png';
import { LAST_CREATED_JOB_TO } from 'redux/actions/types';
import { MAX_ITEM_COUNT_IN_TABLE, MODE } from 'pages/TableOrdering/config';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { createJobTO, deleteJobTO, getJobsOrderingTO, updateJobTO } from 'redux/actions/TableOrdering/jobs';

const defaultValues = {
  title: '',
  code: '',
  payBasis: '',
  defaultPay: '',
  tipped: '',
  cashier: '',
  driver: '',
};

export const jobsSchema = yup.object().shape({
  title: yup.string().required('field_is_required_'),
  code: yup.string(),
  payBasis: yup.string(),
  defaultPay: yup.string().nullable().notRequired().matches(/^\d+$/, {
    message: 'defaultPay must be a valid number',
    excludeEmptyString: true,
  }),
  tipped: yup.string(),
  cashier: yup.string(),
  driver: yup.string(),
});

export const PAY_BASIS_ENUM = {
  HOURLY: 'Hourly',
  SALARY: 'Salary',
};
export const PAY_BASIS_ENUM_EDIT = {
  HOURLY: 'hourly',
  SALARY: 'salary',
};

export const TIPPED_ENUM = {
  YES: 'Yes. This employee must enter cash tips at closing',
  NO: 'No tips need to be entered at closing',
};

export const CASHIER_ENUM = {
  YES: 'Yes',
  NO: 'No ',
};

export const DRIVER_ENUM = {
  YES: 'Yes',
  NO: 'No',
};

export const useTableOrderingJobs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(MODE.CREAT);
  const { jobs, loading, lastCreatedJob } = useSelector(({ tableOrdering }) => tableOrdering);
  const [rowsPerPage, setRowsPerPage] = useState(MAX_ITEM_COUNT_IN_TABLE);
  const { currentBusiness } = useSelector(({ business }) => business);
  const [currentPayBasis, setCurrentPayBasis] = useState(null);
  const [currentTipped, setCurrentTipped] = useState(null);
  const [currentCashier, setCurrentCashier] = useState(null);
  const [currentDriver, setCurrentDriver] = useState(null);
  const formMethods = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(jobsSchema),
  });

  useEffect(() => {
    if (currentBusiness?._id) {
      dispatch(getJobsOrderingTO());
    }
  }, [currentBusiness?._id]);

  const deleteJob = async (data) => {
    const confirmed = await confirm({
      title: t('Confirm Job Deletion'),
      message: t('Are you sure you want to delete this Job?'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
      background: 'rgba(226, 21, 8, 1)',
    });
    if (confirmed) {
      dispatch(deleteJobTO(data?.id));
    }
  };

  useEffect(() => {
    return () => {
      dispatch({ type: LAST_CREATED_JOB_TO, payload: null });
    };
  }, []);

  const editJob = (data) => {
    setOpen(true);
    setMode(MODE.EDIT);
    setCurrentPayBasis(data?.payBasis === PAY_BASIS_ENUM_EDIT.HOURLY ? PAY_BASIS_ENUM.HOURLY : PAY_BASIS_ENUM.SALARY);
    setCurrentCashier(data.cashier ? CASHIER_ENUM.YES : CASHIER_ENUM.NO);
    setCurrentTipped(data.tipped ? TIPPED_ENUM.YES : TIPPED_ENUM.NO);
    setCurrentDriver(data.driver ? DRIVER_ENUM.YES : DRIVER_ENUM.NO);
    formMethods.reset({
      id: data?.id,
      title: data?.title || '',
      code: data?.code?.replace('-', '') || '',
      payBasis: data?.payBasis === PAY_BASIS_ENUM_EDIT.HOURLY ? PAY_BASIS_ENUM.HOURLY : PAY_BASIS_ENUM.SALARY,
      defaultPay: data?.defaultPay || '',
      tipped: data?.tipped ? TIPPED_ENUM.YES : TIPPED_ENUM.NO,
      cashier: data?.cashier ? CASHIER_ENUM.YES : CASHIER_ENUM.NO,
      driver: data?.driver ? DRIVER_ENUM.YES : DRIVER_ENUM.NO,
    });
  };

  const jobColumns = [
    {
      field: 'title',
      headerName: t('table_ordering.job_title'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'code',
      headerName: t('table_ordering.job_code'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'payBasis',
      headerName: t('table_ordering.pay_basis'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'defaultPay',
      headerName: t('table_ordering.default_pay'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'tipped',
      headerName: t('table_ordering.tipped'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ value }) => {
        return <Box>{value ? <img src={JobsCheckMark} alt="jobs-checkmark" /> : ''}</Box>;
      },
    },
    {
      field: 'cashier',
      headerName: t('table_ordering.cashier'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ value }) => {
        return <Box>{value ? <img src={JobsCheckMark} alt="jobs-checkmark" /> : ''}</Box>;
      },
    },
    {
      field: 'driver',
      headerName: t('table_ordering.driver'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ value }) => {
        return <Box>{value ? <img src={JobsCheckMark} alt="jobs-checkmark" /> : ''}</Box>;
      },
    },
    {
      id: 'tools',
      field: 'Tools',
      type: 'actions',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="delete" onClick={() => editJob(params?.row)}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => deleteJob(params?.row)}>
              <img src={DeleteIcon} alt="delete icon" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const jobsFilter = jobs.map((job) => ({
    id: job._id,
    title: job.title || '',
    code: job.code || '-',
    payBasis: job.payBasis,
    defaultPay: job.defaultPay || '-',
    tipped: job.tipped,
    cashier: job.cashier,
    driver: job.driver,
  }));

  const handleCancel = () => {
    formMethods.reset(defaultValues);
    setOpen(false);
    setTimeout(() => {
      setMode(MODE.CREAT);
    }, 300);
  };

  const handleSubmit = (values) => {
    const toBooleanOrNull = (val) => (val != null ? Boolean(val) : null);

    values['cashier'] = toBooleanOrNull(currentCashier);
    values['tipped'] = toBooleanOrNull(currentTipped);
    values['payBasis'] = currentPayBasis?.toLowerCase();
    values['driver'] = toBooleanOrNull(currentDriver);
    values['defaultPay'] = values?.defaultPay || values?.defaultPay === 0 ? Number(values?.defaultPay) : null;
    if (MODE.CREAT === mode) {
      dispatch(createJobTO(values)).then(() => {
        handleCancel();
      });
    } else {
      dispatch(updateJobTO(values)).then(() => {
        handleCancel();
      });
    }
  };

  const handleChangePayBasis = (event) => {
    setCurrentPayBasis(event.target.value);
  };

  const handleChangeTipped = (event) => {
    setCurrentTipped(event.target.value);
  };

  const handleChangeCashier = (event) => {
    setCurrentCashier(event.target.value);
  };

  const handleChangeDriver = (event) => {
    setCurrentDriver(event.target.value);
  };

  return {
    jobsFilter,
    jobColumns,
    setOpen,
    open,
    mode,
    formMethods,
    handleSubmit,
    handleCancel,
    currentPayBasis,
    handleChangePayBasis,
    currentTipped,
    handleChangeTipped,
    currentCashier,
    handleChangeCashier,
    currentDriver,
    handleChangeDriver,
    lastCreatedJob,
    setRowsPerPage,
    rowsPerPage,
    loading,
  };
};
