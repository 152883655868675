import React, { useCallback, useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ORDERS_HISTORY, RESET_ORDERS_PAGINATION } from 'redux/actions/types';
import { logout } from 'redux/actions/user';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import WebsiteIcon from '@mui/icons-material/Language';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useSocket } from 'providers/Socket';
import ReportIcon from 'assets/icons/report_icon.svg';
import TableOrderingIcon from 'assets/images/table.png';
import { useDispatch, useSelector } from 'react-redux';
import Private from './Private';
import PrivatePlan from './PrivatePlan';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { isBrand } from 'hooks/useBrandType';
import { IS_LOGIN_USER, MODIFIED_LOG_OUT } from 'constants/user';
import { isTableOrderingProvider } from 'utils/checkAccessDenied';
import usePermission from '../hooks/permission';
import * as all from 'constants/permissions';
import SkeletonSidebarList, { SkeletonWrap } from './Settings/Skeleton/SkeletonSidebarList';
import usePermissionPlan from 'hooks/permissionPlan';
import USA from 'assets/icons/languageIcon/united-states.png';
import ChinaIcon from 'assets/icons/languageIcon/china.png';
import SpainIcon from 'assets/icons/languageIcon/spain.png';
import ChargeBackIcon from 'assets/icons/chargeBackIcon.svg';

// import RussIcon from 'assets/icons/languageIcon/russia.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { confirm } from './MenuNew/ConfirmModal';

// const sideBarConfig = [
//   {
//     label: 'Home',
//     href: '/',
//     icon: HomeOutlinedIcon,
//     render: () => {},
//   },
//   {
//     label: 'Home',
//     href: '/',
//     icon: HomeOutlinedIcon,
//   },
// ];
const excludePages = ['/website/main', '/website/coupons', '/providers', '/'];
const excludePagesBrand = [
  '/website/main',
  '/website/coupons',
  '/providers',
  '/',
  '/reports',
  '/orders',
  '/orders/history',
];
const Sidebar = ({ newOrders }) => {
  const { t } = useTranslation();

  // const { i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const planId = searchParams.get('plan');
  const corporateBusiness = searchParams.get('corporateBusiness');
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();
  const { data, isLogin, modifiedLogOut } = useSelector(({ user }) => user);
  const { currentBusiness } = useSelector(({ business }) => business);
  const { menu } = useSelector((store) => store);
  const providers = useSelector(({ providers }) => providers);
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { isTypeBrand } = isBrand();
  const { eventEmit } = useSocket();
  const [clickLogOut, setClickLogOut] = useState(false);

  const providerCheck =
    window.location.pathname.includes('/providers') &&
    checkPermissionPlan(all['APP_BUNDLE']) &&
    checkPermission(all['PROVIDERS_VIEW']) &&
    isTypeSingle
      ? !providers?.items?.length
      : 0;
  const skeletonLoading = !currentBusiness?.roles || !data || providerCheck;

  const handleLogout = useCallback(async () => {
    dispatch({ type: MODIFIED_LOG_OUT, payload: true });
    setClickLogOut(true);
    history.push({
      pathname: history.location.pathname,
      state: {
        logOut: true,
      },
    });
  }, [dispatch, data, modifiedLogOut, eventEmit]);

  const openConfirmPopup = async () => {
    if (clickLogOut && modifiedLogOut !== 'opened' && modifiedLogOut) {
      const confirmed = await confirm({
        title: t('providers_settings.are_you_sure'),
        message: t('providers_settings.do_you_logout'),
        cancelText: t('orders.cancel'),
        confirmText: t('providers_settings.yes_logout'),
        confirmColor: 'primary',
      });
      if (confirmed) {
        eventEmit('leave', data?._id);
        dispatch(logout()).then(() => {
          history.push('/login');
          clickLogOut(false);
          dispatch({ type: MODIFIED_LOG_OUT, payload: false });
        });
      } else {
        setClickLogOut(false);
      }
    }
  };

  useEffect(() => {
    openConfirmPopup();
  }, [setClickLogOut, clickLogOut, modifiedLogOut]);

  const isTableOrdering = isTableOrderingProvider(providers?.items);

  useEffect(() => {
    if (!location.pathname.includes('history')) {
      dispatch({
        type: ORDERS_HISTORY,
        payload: {
          data: {
            count: 0,
            orders: [],
          },
        },
      });
    }
  }, [location]);

  useEffect(() => {
    if (token && planId && corporateBusiness) {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        history.push(`/providers?token=${token}`);
      }
    }
  }, [token, planId, corporateBusiness]);

  useEffect(() => {
    if (currentBusiness && Object.keys(currentBusiness).length > 0) {
      if (!isTypeSingle && !isTypeBrand && isLogin) {
        history.push('/orders');
        dispatch({ type: IS_LOGIN_USER, payload: false });
      } else if (!isTypeSingle && !isTypeBrand && !isLogin && excludePages.includes(window.location.pathname)) {
        history.push('/orders');
      } else if (isTypeSingle && isLogin) {
        const redirectUrl = localStorage.getItem('redirectUrl');
        if (redirectUrl) {
          history.push(redirectUrl);
          return;
        }
        if (!checkPermission(all['HOME_REPORT_FILTER'])) {
          history.push('/orders');
        } else history.push('/');
        dispatch({ type: IS_LOGIN_USER, payload: false });
      } else if (isTypeSingle && !isLogin) {
        if (!checkPermission(all['HOME_REPORT_FILTER']) && window.location.pathname === '/') {
          history.push('/orders');
        }
        if (!checkPermission(all['TUTORIAL_VIEW']) && window.location.pathname === '/tutorials') {
          history.push('/orders');
        }
        if (
          (!checkPermission(all['APP_BUNDLE']) && !window.location.search.includes('restaurantmailbox')) ||
          (!checkPermission(all['PROVIDERS_VIEW']) && window.location.pathname === '/providers')
        ) {
          history.push('/orders');
        }
        if (!checkPermission(all['SETTINGS_WEBSITE']) && window.location.pathname === '/website/coupons') {
          history.push('/orders');
        }
        if (!checkPermission(all['SETTINGS_WEBSITE']) && window.location.pathname === '/website/main') {
          history.push('/orders');
        }
        if (!checkPermission(all['WEB_BUNDLE']) && window.location.pathname === '/website/coupons') {
          history.push('/orders');
        }
      } else if (!isTypeSingle && isTypeBrand && isLogin) {
        history.push('/menu/overview');
        dispatch({ type: IS_LOGIN_USER, payload: false });
      } else if (!isTypeSingle && isTypeBrand && !isLogin && excludePagesBrand.includes(window.location.pathname)) {
        history.push('/menu/overview');
      }
    }
  }, [isTypeSingle, isTypeBrand, currentBusiness]);

  return (
    <>
      <Box
        sx={{
          height: '100%',
          // '& .MuiListItem-root': {
          //   '& hover': {
          //     background: 'red !important',
          //   },
          // },
        }}
      >
        <div className="sidebar-body">
          {skeletonLoading ? (
            <SkeletonSidebarList />
          ) : (
            <List
              disablePadding={true}
              sx={{
                '& .Mui-selected': {
                  backgroundColor: '#222933',
                },
              }}
            >
              {isTypeSingle && (
                <Private permission="HOME_REPORT_FILTER">
                  <ListItem selected={location.pathname === '/'} component={Link} exact="true" to="/">
                    <ListItemIcon>
                      <i className="icon icon-home"></i>
                    </ListItemIcon>
                    <ListItemText primary={t('sidebar.home')} />
                    {/* <NavLink className="nav-link" exact='true' to="/">
              <i className="icon icon-home"></i>Home
            </NavLink> */}
                  </ListItem>
                </Private>
              )}
              {!isTypeBrand && (
                <ListItem
                  selected={location.pathname.includes('orders')}
                  onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })}
                  component={Link}
                  exact="true"
                  to="/orders"
                >
                  {/* <NavLink onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })} className="nav-link" to="/orders"> */}
                  <ListItemIcon>
                    <i className="icon icon-orders"></i>
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.orders')}>
                    {!!newOrders && (
                      <Badge pill color="danger" className="ml-auto">
                        {newOrders}
                      </Badge>
                    )}
                  </ListItemText>
                </ListItem>
              )}
              <ListItem
                selected={location.pathname.includes('chargeback')}
                onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })}
                component={Link}
                exact="true"
                to="/chargeback/new"
              >
                {/* <NavLink onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })} className="nav-link" to="/orders"> */}
                <ListItemIcon sx={{ width: 20, height: 20 }}>
                  <img src={ChargeBackIcon} alt="CHARGE_BACK_ICON" />
                </ListItemIcon>
                <ListItemText primary={t('sidebar.chargeback')} />
              </ListItem>
              {/*{checkPermission(all['SUPER']) && (*/}
              {/*  <ListItem*/}
              {/*    selected={location.pathname.includes('onboarding')}*/}
              {/*    component={Link}*/}
              {/*    exact="true"*/}
              {/*    to="/onboarding"*/}
              {/*  >*/}
              {/*    <ListItemIcon>*/}
              {/*      <CheckCircleOutlinedIcon />*/}
              {/*    </ListItemIcon>*/}
              {/*    <ListItemText primary={t('sidebar.onboarding')} />*/}
              {/*  </ListItem>*/}
              {/*)}*/}
              {!isTypeBrand && (
                <ListItem
                  selected={location.pathname.includes('history')}
                  onClick={() => dispatch({ type: RESET_ORDERS_PAGINATION })}
                  component={Link}
                  exact="true"
                  to="/history"
                >
                  <ListItemIcon>
                    <i className="icon icon-history"></i>
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.orders_history')}>
                    {!!newOrders && (
                      <Badge pill color="danger" className="ml-auto">
                        {newOrders}
                      </Badge>
                    )}
                  </ListItemText>
                </ListItem>
              )}
              <ListItem
                selected={location.pathname.includes('/menu')}
                component={Link}
                exact="true"
                to="/menu/overview"
              >
                <ListItemIcon>
                  <i className="icon icon-menu"></i>
                </ListItemIcon>
                <ListItemText>{t('menu.name')}</ListItemText>
              </ListItem>
              {isTableOrdering && (
                <ListItem
                  selected={location.pathname.includes('/table-ordering')}
                  component={Link}
                  exact="true"
                  to="/table-ordering/tables"
                >
                  <ListItemIcon>
                    <img
                      src={TableOrderingIcon}
                      alt="TABLE_ORDERING_ICON"
                      className="table-ordering-icon"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Table Ordering" />
                </ListItem>
              )}
              {!isTypeBrand && (
                <Private permission="HOME_REPORT_FILTER">
                  <ListItem
                    selected={location.pathname.includes('/reports')}
                    component={Link}
                    exact="true"
                    to="/reports"
                  >
                    <ListItemIcon>
                      <img src={ReportIcon} alt="REPORT_ICON" className="report-icon" />
                    </ListItemIcon>
                    <ListItemText>{t('sidebar.reports')}</ListItemText>
                  </ListItem>
                </Private>
              )}
              {isTypeSingle && (
                <PrivatePlan permission="APP_BUNDLE">
                  <Private permission="PROVIDERS_VIEW">
                    <ListItem
                      selected={location.pathname.includes('/providers')}
                      component={Link}
                      exact="true"
                      to="/providers"
                    >
                      <ListItemIcon>
                        <i className="icon icon-services"></i>
                      </ListItemIcon>
                      <ListItemText>{t('sidebar.providers')}</ListItemText>
                    </ListItem>
                  </Private>
                </PrivatePlan>
              )}
              {!isTypeBrand && (
                <PrivatePlan permission="WEB_BUNDLE">
                  <Private permission="SETTINGS_WEBSITE">
                    {/* {currentBusiness?.settings?.deliveryBy[0]?.deliveryService != 'fetchme' && <ModalDoordash />} */}

                    <ListItem
                      selected={location.pathname.includes('/website')}
                      component={Link}
                      exact="true"
                      to="/website"
                    >
                      {/* <NavLink className="nav-link" to="/website"> */}
                      <ListItemIcon>
                        <WebsiteIcon sx={{ marginRight: '1rem', verticalAlign: 'top', fontSize: '20px' }} />
                      </ListItemIcon>
                      <ListItemText>{t('menu.website')}</ListItemText>
                      {/* </NavLink> */}
                    </ListItem>
                  </Private>
                </PrivatePlan>
              )}
              <ListItem
                selected={
                  location.pathname.includes('/settings') &&
                  !location.pathname.includes('table-ordering') &&
                  !location.pathname.includes('/website') &&
                  !location.pathname.includes('/providers')
                }
                component={Link}
                exact="true"
                to="/settings/business"
              >
                {/* <NavLink className="nav-link" to="/settings"> */}
                <ListItemIcon>
                  <i className="icon icon-gear"></i>
                </ListItemIcon>
                <ListItemText>{t('sidebar.settings')}</ListItemText>
                {/* </NavLink> */}
              </ListItem>
              <Private permission="TUTORIAL_VIEW">
                <ListItem
                  selected={location.pathname.includes('/tutorials')}
                  component={Link}
                  exact="true"
                  to="/tutorials"
                >
                  <ListItemIcon>
                    <i className="icon icon-book-open"></i>
                  </ListItemIcon>
                  <ListItemText>{t('sidebar.tutorials')}</ListItemText>
                </ListItem>
              </Private>
              <ListItem selected={location.pathname.includes('/help')} component={Link} exact="true" to="/help">
                <ListItemIcon>
                  <i className="icon icon-help-circle"></i>
                </ListItemIcon>
                <ListItemText>{t('help.name')}</ListItemText>
              </ListItem>
              <ListItem selected={location.pathname.includes('/schedule')} component={Link} exact="true" to="/schedule">
                <ListItemIcon>
                  <i className="icon icon-clock"></i>
                </ListItemIcon>
                <ListItemText>{t('schedule.name')}</ListItemText>
              </ListItem>
              {/*<Box>*/}
              {/*  <ListItem*/}
              {/*    sx={{*/}
              {/*      py: 3,*/}
              {/*      borderTop: `1px solid #646973`,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <>*/}
              {/*      <IconButton onClick={() => changeLanguage('en')} size="small">*/}
              {/*        <img src={USA} />*/}
              {/*      </IconButton>*/}
              {/*      <IconButton size="small" onClick={() => changeLanguage('china')}>*/}
              {/*        <img src={ChinaIcon} />*/}
              {/*      </IconButton>*/}
              {/*      <IconButton size="small" onClick={() => changeLanguage('spain')}>*/}
              {/*        <img src={SpainIcon} />*/}
              {/*      </IconButton>*/}
              {/*    </>*/}
              {/*  </ListItem>*/}
              {/*</Box>*/}
            </List>
          )}
        </div>
        <Box className={'logout-block'} sx={{ width: '100%' }}>
          {skeletonLoading ? (
            <>
              <SkeletonWrap variant="text" height={5} width={239} />
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pr: 2, height: '81px' }}>
                <Box sx={{ minWidth: '56px' }}>
                  <SkeletonWrap variant="rounded" width={20} height={20} />
                </Box>
                <SkeletonWrap variant="rounded" width={151} height={24} />
              </Box>
            </>
          ) : (
            <ListItem
              onClick={handleLogout}
              sx={{
                py: 3,
                borderTop: `1px solid #646973`,
              }}
            >
              <>
                <IconButton size="small">
                  <i className="icon icon-log-out"></i>
                </IconButton>
                <ListItemText sx={{ cursor: 'pointer' }} primary={t('sidebar.log_Out')} />
              </>
            </ListItem>
          )}
        </Box>
      </Box>
    </>
  );
};
export default Sidebar;
