import React, { useState, useCallback, useEffect } from 'react';
import { getOrdersHistory } from 'redux/actions/orders';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import OrderView from 'components/Order/OrderView';
import OrdersHistoryList from 'components/Order/OrdersHistoryList';
import useQuery from 'hooks/useQuery';
import { ORDERS_FILTER_STATUS_HISTORY } from 'constants/config';
import { ORDERS_HISTORY_LOADING } from 'redux/actions/types';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import dayjs from 'dayjs';
import Preloader from 'components/Preloader';
import useProviderConfig from 'hooks/useProviderConfig';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';

const OrdersHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  localStorage.removeItem('redirectUrl');
  const { data } = useSelector(({ user }) => user);
  const [order, setOrder] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [pageTable, setPage] = useState(0);
  const { orderHistoryList, orderHistoryCount, orderHistoryLoading } = useSelector(({ orders }) => orders);
  const { locationIdsForHistory, brandIdsForHistory, filterBrandList } = useSelector(({ business }) => business);
  let { current } = useParams();
  const [orderLoading, setOrderLoading] = useState(false);
  const query = useQuery();
  const { groups } = useProviderConfig();
  const checkPermission = usePermission();
  const startDateQuery = query.get('start');
  const endDateQuery = query.get('end');
  const skipQuery = query.get('skip') || 0;
  const providersList = Object.keys(groups).map((provider) => provider.toLowerCase()) || [];
  const statusesList = Object.values(ORDERS_FILTER_STATUS_HISTORY).map((item) => item.value) || [];
  const [show, setShow] = useState(true);

  useEffect(() => {
    const idsBrandOrderHistory = [];
    filterBrandList.forEach((item) => {
      if (item?._id) {
        return idsBrandOrderHistory.push(item?._id);
      }
    });
    const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
    const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
    const body = {
      start: startDateQuery ? Number(startDateQuery) : dayjs().tz().subtract(1, 'w').startOf('day').valueOf(),
      end: endDateQuery ? Number(endDateQuery) : dayjs().tz().endOf('day').valueOf(),
      skip: Number(skipQuery),
      limit: Number(pageSize),
      providers: providersList,
      statuses: statusesList,
      brandIds: checkPermission(all['SUPER'])
        ? [selectedBrand?.id]
        : brandIdsForHistory.length === 0
        ? idsBrandOrderHistory
        : brandIdsForHistory || [],
      locationIds: checkPermission(all['SUPER']) ? [selectedLocation?.value?._id] : locationIdsForHistory || [],
    };
    localStorage.setItem('requestData', JSON.stringify(body));
    const skip = pageTable * pageSize;
    history.push(`${history.location.pathname}?skip=${skip}&limit=${pageSize}&page=${pageTable}`);
    dispatch({ type: ORDERS_HISTORY_LOADING, payload: true });
    setOrderLoading(true);
    dispatch(getOrdersHistory(body, pageTable)).then(() => {
      setOrderLoading(false);
      dispatch({ type: ORDERS_HISTORY_LOADING, payload: false });
    });
  }, []);

  useEffect(() => {
    const selectedOrder = orderHistoryList?.find((item) => (item?.id || item?._id) === current);
    setOrder(selectedOrder);
  }, [order, current, orderHistoryList]);

  const handleSelect = useCallback(
    ({ _id }) => {
      history.replace({
        pathname: `/history/${current === _id ? '' : `${_id}`}`,
        search,
      });
    },
    [current, history, search]
  );

  const resizeHandler = () => {
    if (window.innerWidth < 1600) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    resizeHandler();
  }, [show]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [show]);

  return (
    <ErrorBoundaryWrapper>
      <Box display="flex">
        <div className="order-content">
          <div className="orderListBlock">
            <OrdersHistoryList
              data={orderHistoryList}
              count={orderHistoryCount}
              setPageSize={setPageSize}
              setPage={setPage}
              pageTable={pageTable}
              pageSize={pageSize}
              onSelect={handleSelect}
              loading={orderHistoryLoading}
            />
          </div>
        </div>
        {orderHistoryLoading && <Preloader overlay />}
        {(show && !orderHistoryLoading) || (current && !orderHistoryLoading) ? (
          <OrderView
            orderList={orderHistoryList}
            page={pageTable}
            data={order}
            business={data}
            orderId={current}
            loading={orderLoading}
          />
        ) : (
          <></>
        )}
      </Box>
    </ErrorBoundaryWrapper>
  );
};

export default OrdersHistory;
