import React, { useEffect, useMemo, useState } from 'react';
import 'date-fns';
import { getReportList, setExportCSV, getChargeBackReportList, setExportChargeBackCSV } from 'redux/actions/reports';
import { useSelector, useDispatch } from 'react-redux';
import Preloader from 'components/Preloader';
import FilterReport from './Filter';
import TableReport from './Table';
import { EMPTY_REPORT_LIST, SET_REPORT_LOADING } from 'redux/actions/types';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { allDays, columnTitles } from './configTable';
import useProviderConfig from 'hooks/useProviderConfig';
import { PROVIDER_CONNECTED } from 'constants/providerStatuses';

const tableHeadersHandler = () => {
  return columnTitles;
};

const Reports = () => {
  const dispatch = useDispatch();
  const { groups } = useProviderConfig();
  const { items } = useSelector(({ providers }) => providers);
  const { headerKeys } = useSelector(({ reports }) => reports);
  const CHARGEBACK_DISPUTE_REPORT = 'CHARGEBACK_DISPUTE_REPORT';
  const { brandIdsForReport, locationIdsForReport } = useSelector(({ business }) => business);
  localStorage.removeItem('redirectUrl');
  const filteredList = useMemo(() => {
    const withFiltered = items.filter((item) => {
      if (item.status === PROVIDER_CONNECTED) {
        return true;
      }
    });
    withFiltered.forEach((provider) => {
      const srv = provider?.srv;
      const isInGroup = Object.keys(groups).some((group) => groups[group]?.includes(srv));
      if (!isInGroup) {
        groups[srv] = [srv];
      }
    });
    return groups;
  }, [items, groups]);

  const filterInitialValues = {
    type: 'SUMMARY',
    start_date: new Date(),
    end_date: new Date(),
    days_of_week: allDays.map((item) => item.value),
    brandIds: brandIdsForReport || [],
    locationIds: locationIdsForReport || [],
    providers: Object.keys(filteredList),
  };
  const { reportList, loading, filterList } = useSelector(({ reports }) => reports);
  const [model, setModel] = useState({
    ...filterInitialValues,
  });

  const [currentFilter, setCurrentFilter] = useState(null);
  const [filterSubmitted, handleFilterSubmitted] = useState(false);
  const [lastReportFilter, handleLastReportFilter] = useState(filterInitialValues);
  const [tableTypeName, handleTableTypeName] = useState(filterInitialValues.type);
  const [saveTypeValue, handleSaveTypeValue] = useState(filterList.filter((item) => item.name));
  const [page, setPage] = useState(0);
  const [loadingExport, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [errorText, setErrorText] = useState(false);
  const [currentReport, setCurrentReport] = useState(filterInitialValues);

  useEffect(() => {
    setModel(filterInitialValues);
  }, [items, groups]);

  useEffect(() => {
    // dispatch(getReportFilter());
    // return () => {
    dispatch({ type: EMPTY_REPORT_LIST, payload: [] });
    // };
  }, []);

  const handleFilterSubmit = (modelData) => {
    handleFilterSubmitted(true);
    setCurrentReport(modelData);
    handleLastReportFilter(modelData);
    setPage(0);
    dispatch({ type: SET_REPORT_LOADING, payload: true });
    if (modelData.type === CHARGEBACK_DISPUTE_REPORT) {
      dispatch(
        getChargeBackReportList({
          ...modelData,
          start_date: modelData.start_date,
          end_date: modelData.end_date,
        })
      );
    } else {
      dispatch(
        getReportList({
          ...modelData,
          start_date: modelData.start_date,
          end_date: modelData.end_date,
        })
      );
    }
  };

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // handleExport
  const handleExport = () => {
    setLoading(true);
    if (currentReport.type === CHARGEBACK_DISPUTE_REPORT) {
      dispatch(setExportChargeBackCSV({ ...lastReportFilter }))
        .then(() => {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        })
        .catch(() => setLoading(false));
    } else {
      dispatch(setExportCSV({ ...lastReportFilter }))
        .then(() => {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        })
        .catch(() => setLoading(false));
    }
  };
  return (
    <div className="website-settings report-page">
      <div className="card report-card">
        <ErrorBoundaryWrapper>
          <FilterReport
            tableTypeName={tableTypeName}
            model={model}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            filterInitialValues={filterInitialValues}
            errorText={errorText}
            handleTableTypeName={handleTableTypeName}
            setModel={setModel}
            handleFilterSubmit={handleFilterSubmit}
            setErrorText={setErrorText}
            filteredList={filteredList}
          />
          <TableReport
            lastReportFilter={lastReportFilter}
            currentFilter={currentFilter}
            currentReport={currentReport}
            loadingExport={loadingExport}
            tableTypeName={tableTypeName}
            filterSubmitted={filterSubmitted}
            loading={loading}
            reportList={reportList}
            page={page}
            saveTypeValue={saveTypeValue}
            rowsPerPage={rowsPerPage}
            tableHeadersHandler={tableHeadersHandler}
            setPage={setPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleExport={handleExport}
            headerKeys={headerKeys}
          />
        </ErrorBoundaryWrapper>
      </div>
      {loading && <Preloader overlay />}
    </div>
  );
};

export default Reports;
