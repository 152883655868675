import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PAUSE_RESUME_STATUSES, PAUSE_RESUME_STATUSES_LABEL } from 'constants/resumePause';
import moment from 'moment-timezone';

export function useProviderTimer(providers) {
  const { t } = useTranslation();
  const [updatedProviders, setUpdatedProviders] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const updated = providers.map((provider) => {
        if (provider?.paused_until) {
          const timeLeft = provider.paused_until - now;
          if (timeLeft <= 0) {
            provider.provider_status = PAUSE_RESUME_STATUSES.RECEIVING;
            provider.paused_until = null;
            provider.immutable_until = null;
            provider.time_remaining = t(`${PAUSE_RESUME_STATUSES_LABEL.RECEIVING}`);
          } else {
            const minutes = String(Math.floor((timeLeft % 3600) / 60)).padStart(2, '0');
            const seconds = String(timeLeft % 60).padStart(2, '0');
            let timerMs = provider?.paused_until ? provider?.paused_until * 1000 - Date.now() : 1000;
            let duration = moment.duration(timerMs, 'milliseconds');
            if (duration > 700000000) {
              provider.time_remaining = t(`store_status_notification.permanently_paused_title`);
            } else {
              provider.time_remaining = `${provider?.provider_status || ''} ${minutes}:${seconds}`;
            }
          }
        } else {
          provider.provider_status = PAUSE_RESUME_STATUSES.RECEIVING;
          provider.paused_until = null;
          provider.immutable_until = null;
          provider.time_remaining = t(`${PAUSE_RESUME_STATUSES_LABEL.RECEIVING}`);
        }
        return provider;
      });
      setUpdatedProviders([...updated]);
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [updatedProviders]);

  return updatedProviders;
}
