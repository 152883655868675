import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Tables from 'pages/TableOrdering/Tables/Tables';
import Sections from 'pages/TableOrdering/Sections/Sections';
import Settings from 'pages/TableOrdering/Settings/Settings';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import { routes } from 'pages/TableOrdering/TabsTabel/TabsTable';
import { useSelector } from 'react-redux';
import { isTableOrderingProvider } from 'utils/checkAccessDenied';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import { isBrand } from 'hooks/useBrandType';
import usePermissionPlan from 'hooks/permissionPlan';

const TableOrdering = () => {
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();
  const { isTypeBrand } = isBrand();
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { currentBusiness } = useSelector(({ business }) => business);
  const history = useHistory();
  const providers = useSelector(({ providers }) => providers);
  localStorage.removeItem('redirectUrl');
  useEffect(() => {
    if (currentBusiness) {
      if (providers?.items?.length > 0 && isTypeSingle) {
        if (!isTableOrderingProvider(providers?.items)) {
          const targetRoute = checkPermission(all['HOME_REPORT_FILTER']) ? '/home' : '/orders';
          history.push(targetRoute);
        }
      } else if (providers?.items?.length === 0 && !isTypeSingle) {
        if (isTypeBrand) {
          history.push('/menu');
        } else {
          const targetRoute = checkPermission(all['HOME_REPORT_FILTER']) && isTypeSingle ? '/home' : '/orders';
          history.push(targetRoute);
        }
      } else if (
        (isTypeSingle && providers?.items?.length === 0 && !checkPermissionPlan(all['APP_BUNDLE'])) ||
        !checkPermission(all['PROVIDERS_VIEW'])
      ) {
        if (!isTableOrderingProvider(providers?.items)) {
          history.push('/home');
        }
      }
    }
  }, [providers?.items, isTypeSingle, currentBusiness, isTypeBrand, checkPermissionPlan, checkPermission, history]);
  return (
    <Box>
      <ErrorBoundaryWrapper>
        <Switch>
          <Route exact path="/table-ordering/tables" component={Tables} />
          <Route exact path="/table-ordering/sections" component={Sections} />
          <Route exact path="/table-ordering/settings" component={Settings} />
          <Redirect to={`/table-ordering${routes[0]?.path}`} />
        </Switch>
      </ErrorBoundaryWrapper>
    </Box>
  );
};

export default TableOrdering;
