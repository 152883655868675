import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PROVIDER_CONNECTED } from 'constants/providerStatuses';
import { StyledProviderRenderActionsConnected, StyledProviderRenderActionsConnectedPickUp } from './styles';

const AutoConfirmInfo = ({ providers, data }) => {
  const { t } = useTranslation();

  return (
    <>
      {providers[data.srv]?.options?.showSettingsButton && data?.status === PROVIDER_CONNECTED ? (
        <>
          <StyledProviderRenderActionsConnectedPickUp>
            {t('Pick-up Order Auto-Confirm:')}&nbsp;
            {`${data?.orderConfirm?.pickup?.autoConfirm ? t('On') : t('Off')}`}
          </StyledProviderRenderActionsConnectedPickUp>
          <StyledProviderRenderActionsConnected>
            {t('Delivery Order Auto-Confirm:')}&nbsp;
            {`${data?.orderConfirm?.delivery?.autoConfirm ? t('On') : t('Off')}`}
          </StyledProviderRenderActionsConnected>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default memo(AutoConfirmInfo);
