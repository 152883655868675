import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleSocketsEventsByType } from 'redux/sockets';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import logo from 'assets/images/logo.png';
import AddEditSchedule from 'pages/Schedule/addEditSchedule';
import { getBusinessConfig, getProviders, getProvidersStatuses } from 'redux/actions/providers';
import { updateStopResumeOrders } from 'redux/actions/providers';
import Schedule from 'pages/Schedule/Schedule';
import Providers from 'pages/Providers/index';
import Settings from 'pages/Settings';
import WebsiteSettings from 'pages/WebsiteSettings';
import Orders from 'pages/Orders';
// import Home from 'pages/Home';
import Help from 'pages/Help';
import Menu from 'pages/MenuNew/index';
import Reports from 'pages/Report';
import Tutorials from 'pages/Tutorials';
import Sidebar from 'components/Sidebar';
import Preloader from 'components/Preloader';
import ProtectedRoute from 'components/ProtectedRoute';
import { useSocket } from 'providers/Socket';
import BrandSignUp from 'pages/Auth/BrandSignUp/index';
import ResumePause from 'components/PauseResume/index';
import { PROVIDER_CONNECTED } from 'constants/providerStatuses';
import OverlayOrders from 'components/Order/OverlayOrders';
import Header from 'components/Header/index';
import { useIsWidthDown } from 'hooks/mediaQuery';
import { RESET_MODIFIED_ORDERS, ORDERS_EMPTY, RESET_CANCELED_ORDERS } from 'redux/actions/types';
import OrdersHistory from './OrdersHistory';
import AddLocation from './AddLocation';
import PaymentNotificationDialog from 'components/PaymentNotificationModal';
import { getPaymentInfo } from 'redux/actions/paymentNotification';
import InvitationLocation from './InvitationLocation/InvitationLocation';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import usePermissionPlan from 'hooks/permissionPlan';
import TableOrdering from './TableOrdering';
import { SkeletonWrap } from 'components/Settings/Skeleton/SkeletonSidebarList';
import HubSpotChatPage from './HubSpotChatPage';
import { companyTypesEnum } from 'constants/companyTypes';
import OnBoarding from 'components/OnBoarding/OnBoarding';
import ChargeBack from './Chargeback';
import NewHome from 'components/NewHome';
import useQuery from 'hooks/useQuery';
import ResumePauseProviderIndex from 'components/PauseResume/PauseResumeProviderIndex';

const drawerWidth = 240;
const MainBlock = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

// const INNER_WIDTH_CURRENT = 1432;
// const INNER_WIDTH_SMALL_WIDTH = 1034;
// const INNER_WIDTH_SMALL_MARGIN = 125;
// const INNER_WIDTH_BIG_MARGIN = 200;

function Main() {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkPermission = usePermission();
  const checkPermissionPlan = usePermissionPlan();
  let headerHeightWithPadding =
    history.location.pathname.includes('menu') || history.location.pathname.includes('table-ordering')
      ? document.getElementsByTagName('header')[0]?.offsetHeight + 16
      : document.getElementsByTagName('header')[0]?.offsetHeight;
  const { isTypeSingle } = isPermissionWithCompanyType();
  const mdDown = useIsWidthDown('md');
  const { subscribe, unsubscribe, eventEmit } = useSocket();
  const [overlay, setOverlay] = useState(false);
  const [joined, setJoined] = useState(false);
  const query = useQuery();
  const mode = query.get('mode');
  const entityType = query.get('entityType');

  const {
    data,
    loading,
    newOrders,
    modifiedOrders,
    lastUpdatedId,
    lastCanceledId,
    canceledOrders,
    providers,
  } = useSelector(
    ({
      user: { data, loading },
      orders: { newOrders, modifiedOrders, lastUpdatedId, lastCanceledId, canceledOrders },
      menu: { menus },
      providers,
    }) => ({
      data,
      loading,
      newOrders,
      modifiedOrders,
      canceledOrders,
      lastUpdatedId,
      lastCanceledId,
      providers,
    })
  );
  const { currentBusiness } = useSelector(({ business }) => business);

  const [allDisconnected, setAllDisconnected] = useState(true);
  const [currentValue, setCurrentValue] = useState(0);
  const { items, stopResumeOrders } = useSelector(({ providers }) => providers);
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const providerCheck =
    window.location.pathname.includes('/providers') &&
    checkPermissionPlan(all['APP_BUNDLE']) &&
    checkPermission(all['PROVIDERS_VIEW']) &&
    isTypeSingle
      ? !providers?.items?.length
      : 0;
  const skeletonLoading = !currentBusiness?.roles || !data || providerCheck;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (mode === 'create' && entityType === 'modifier') {
      window.scrollTo(0, 0);
    }
  }, [window, mode, entityType]);

  useEffect(() => {
    setOpen(!mdDown);
  }, [mdDown]);

  useEffect(() => {
    if (
      !location?.pathname.includes('/menu') &&
      !location?.pathname.includes('/help') &&
      !location?.pathname.includes('/orders')
    ) {
      document.querySelector('[name="viewport"]').content = 'width=1440,initial-scale=1,shrink-to-fit=yes';
    }
    if (checkPermission(all['PROVIDERS_VIEW'])) {
      dispatch(getProviders());
    }
    if (currentBusiness?.type === companyTypesEnum.SINGLE) {
      dispatch(getProvidersStatuses());
    }
  }, [currentBusiness]);

  // Will uncomment after backend fix

  useEffect(() => {
    if (currentBusiness?._id && currentBusiness?.roles?.name && currentBusiness?.roles?.name !== 'super') {
      dispatch(getPaymentInfo(currentBusiness._id));
    }
  }, [currentBusiness, dispatch]);

  useEffect(() => {
    const handleSocketEvent = (data) => {
      handleSocketsEventsByType(data);
    };
    subscribe('event-bus', handleSocketEvent);

    return () => {
      unsubscribe('event-bus', handleSocketEvent);
    };
  }, [dispatch, subscribe, unsubscribe, eventEmit, data]);

  useEffect(() => {
    if (data) {
      setJoined(true);
      if (!joined) {
        eventEmit('join', { id: data._id });
      }
    }
  }, [data, joined]);

  useEffect(() => {
    if (newOrders.length || modifiedOrders.length || canceledOrders.length) {
      setOverlay(true);
    } else {
      setOverlay(false);
    }
  }, [newOrders.length, modifiedOrders.length, canceledOrders.length]);

  useEffect(() => {
    const filteredList = items.filter((item) => item.srv !== 'postmates');
    filteredList.forEach((item) => {
      if (item.status === PROVIDER_CONNECTED || currentBusiness?.settings?.websiteInfo?.pause) {
        setAllDisconnected(false);
      }
    });
  }, [items, currentBusiness]);

  // useLayoutEffect(() => {
  //   let headerHeightWithPadding = document.getElementsByTagName('header')[0]?.offsetHeight;
  //   if (history.location.pathname.includes('menu')) {
  //     console.log(headerHeightWithPadding + 40, 'menu>>>>>>>>>>>>>>>>>>>>')
  //     setCurrentValue(headerHeightWithPadding + 40);
  //     // if (window.innerWidth < INNER_WIDTH_CURRENT && window.innerWidth > INNER_WIDTH_SMALL_WIDTH) {
  //     //   setCurrentValue(INNER_WIDTH_BIG_MARGIN);
  //     // } else if (window.innerWidth < INNER_WIDTH_SMALL_WIDTH) {
  //     //   setCurrentValue(INNER_WIDTH_BIG_MARGIN);
  //     // } else {
  //     //   if (window.innerWidth < 1600) {
  //     //     setCurrentValue(INNER_WIDTH_BIG_MARGIN);
  //     //   } else {
  //     //     setCurrentValue(INNER_WIDTH_SMALL_MARGIN);
  //     //   }
  //     // }
  //   } else if (history.location.pathname.includes('table-ordering')) {
  //     console.log(headerHeightWithPadding + 40, 'table-ordering>>>>>>>>>>>>>>>>>>>>')
  //     setCurrentValue(headerHeightWithPadding + 40);
  //   } else {
  //     setCurrentValue(headerHeightWithPadding);
  //   }
  //
  //   const handleResize = () => {
  //     let headerHeightWithPadding = document.getElementsByTagName('header')[0]?.offsetHeight;
  //     if (headerHeightWithPadding) {
  //       setCurrentValue(headerHeightWithPadding);
  //     }
  //   };
  //   const windowResize = window.addEventListener('resize', handleResize);
  //   return () => {
  //     // eslint-disable-next-line no-restricted-globals
  //     removeEventListener('resize', windowResize);
  //   };
  // }, [history.location.pathname, isTouched, currentBusiness]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (
        history.location.pathname.includes('menu') ||
        history.location.pathname.includes('table-ordering') ||
        history.location.pathname.includes('providers')
      ) {
        headerHeightWithPadding = document.getElementsByTagName('header')[0]?.offsetHeight + 16;
        if (headerHeightWithPadding) {
          setCurrentValue(headerHeightWithPadding);
        }
      }
    };
    const windowResize = window.addEventListener('resize', handleResize);
    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('resize', windowResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getBusinessConfig());
  }, []);

  // useEffect(() => {
  //   if (history?.location?.pathname.includes('menu')) {
  //     const header = document.getElementsByTagName('header')[0];
  //     console.log('header.offsetHeight', header.offsetHeight);
  //     setCurrentValue(header.offsetHeight + 30);
  //   }
  // }, [history?.location?.pathname]);

  const openOrders = () => {
    history.push({
      pathname: `/orders${
        newOrders.length || modifiedOrders.length || canceledOrders.length ? `/${lastCanceledId || lastUpdatedId}` : ''
      }`,
      search: `?period=today&filter=all`,
    });
    setOverlay(false);
    dispatch({ type: ORDERS_EMPTY });
    dispatch({ type: RESET_MODIFIED_ORDERS });
    dispatch({ type: RESET_CANCELED_ORDERS });
  };

  if (location.pathname.includes('/addBrand')) {
    return <BrandSignUp data={data} />;
  } else if (location.pathname.includes('/addLocation')) {
    return <AddLocation data={data} />;
  } else if (location.pathname.includes('/invitationLocation')) {
    return <InvitationLocation />;
  }

  if (history.location.pathname.includes('/help/chat')) {
    return (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Switch>
          <Route path="/help/chat/:businessId" component={HubSpotChatPage} />
        </Switch>
      </Box>
    );
  }
  const getBusinessTitle = (business) => {
    if (business && business.disabled && business?.locationInfo?.locationName) {
      return `${business?.title || business.companyName} - ${business?.locationInfo?.locationName} (Disabled)`;
    }

    if (business && business.disabled && !business?.locationInfo?.locationName) {
      return `${business?.title || business.companyName} (Disabled)`;
    }

    if (business && !business.disabled && business?.locationInfo?.locationName) {
      return `${business?.title || business.companyName} - ${business?.locationInfo?.locationName}`;
    }

    return `${business?.title || business.companyName}`;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Header handleDrawerOpen={handleDrawerOpen} open={open} drawerWidth={drawerWidth} />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            overflowX: 'hidden',
            zIndex: 113,
          },

          '& .MuiPaper-root': {
            backgroundColor: '#3c4351',
          },
          '& .MuiListItemIcon-root': {
            color: (theme) => theme.palette.common.white,
          },
          '& .MuiListItemText-root': {
            color: (theme) => theme.palette.common.white,
          },
          '& .MuiButtonBase-root': {
            color: (theme) => theme.palette.common.white,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box>
            <Box sx={{ display: 'flex' }}>
              {skeletonLoading ? (
                <Box sx={{ width: '40px', height: '125px', display: 'flex', alignItems: 'center' }}>
                  <SkeletonWrap variant="circular" width={24} height={24} />
                </Box>
              ) : (
                <IconButton id="mainChevron" disableRipple={true} onClick={handleDrawerClose}>
                  {<ChevronLeftIcon />}
                </IconButton>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 2 }}>
                <ListItem to="/" component={Link}>
                  <ListItemIcon sx={{ width: '40px' }}>
                    {skeletonLoading ? (
                      <SkeletonWrap variant="circular" width={56} height={56} />
                    ) : (
                      <img width="100%" src={logo} alt="order.co" />
                    )}
                  </ListItemIcon>

                  <ListItemText
                    sx={{ color: (theme) => theme.palette.common.white, ml: 2 }}
                    // secondary={data?.business?.companyName}
                    primary={
                      <Box>
                        {skeletonLoading ? (
                          <SkeletonWrap animation="wave" height={20} width="70px" />
                        ) : (
                          <Typography>Orders.co</Typography>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              </Box>
            </Box>
          </Box>
        </DrawerHeader>
        <Box>
          {skeletonLoading ? (
            <SkeletonWrap animation="wave" height={20} width="77px" />
          ) : (
            <Typography
              sx={{
                fontSize: '14px',
                color: ' #bcbec3',
                ml: 2,
                mb: 2,
              }}
            >
              {getBusinessTitle(currentBusiness)}
            </Typography>
          )}
        </Box>
        {skeletonLoading ? <SkeletonWrap variant="text" height={5} sx={{ mb: 2 }} width={239} /> : <Divider />}
        <Sidebar newOrders={newOrders.length} />

        <Divider />
      </Drawer>
      <MainBlock open={open}>
        {/* <DrawerHeader /> */}
        <Box sx={{ mt: `${headerHeightWithPadding}px` }}>
          {skeletonLoading || !providers.items ? (
            <Preloader overlay className="app-loader" />
          ) : (
            <Box>
              <Switch>
                {data?.roles?.name !== 'user' && (
                  <ProtectedRoute exact permission="HOME_REPORT_FILTER" path="/" component={NewHome} />
                )}
                <Route exact path="/help" component={Help} />
                <Route exact path="/orders/:current?" component={Orders} />
                <Route exact path="/onboarding" component={OnBoarding} />
                <Route exact path="/history/:current?" component={OrdersHistory} />
                <Route exact path="/schedule" component={Schedule} />
                <ProtectedRoute exact path="/schedule/new/:id?" component={AddEditSchedule} />
                <Route path="/settings" component={Settings} />
                <ProtectedRoute path="/website" component={WebsiteSettings} />
                <Route path="/tutorials" component={Tutorials} />
                <ProtectedRoute permission="HOME_REPORT_FILTER" path="/reports" component={Reports} />
                <Route path="/menu" component={Menu} />
                <Route path="/chargeback" component={ChargeBack} />
                <Route path="/table-ordering" component={TableOrdering} />
                <ProtectedRoute path="/providers" component={Providers} permission="PROVIDERS_VIEW" />
                <Redirect from="/payment-success" to="/website/main" />
                <Redirect to={data?.roles?.name !== 'user' ? '/' : '/orders'} />
              </Switch>
            </Box>
          )}
        </Box>
        <PaymentNotificationDialog />
        {overlay && (
          <OverlayOrders
            openOrders={openOrders}
            setOverlay={setOverlay}
            newOrders={newOrders}
            modifiedOrders={modifiedOrders}
            canceledOrders={canceledOrders}
          />
        )}
        {isTypeSingle && stopResumeOrders?.provider === 'all' ? (
          <ResumePause
            providers={items}
            updateStopResumeOrders={updateStopResumeOrders}
            stopResumeOrders={stopResumeOrders}
          />
        ) : (
          <>
            {isTypeSingle && stopResumeOrders?.provider !== 'all' ? (
              <ResumePauseProviderIndex
                providers={items}
                updateStopResumeOrders={updateStopResumeOrders}
                stopResumeOrders={stopResumeOrders}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </MainBlock>
    </Box>
  );
}

export default Main;
