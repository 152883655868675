import React, { useState, useEffect } from 'react';
import Success from './Success';
import LegaInfo from './LegaInfo';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SignUpTitle } from './styles';
import BillingInfo from './BillingInfo';
import AccountInfo from './AccountInfo';
import { loadStripe } from '@stripe/stripe-js';
import Typography from '@mui/material/Typography';
import { Elements } from '@stripe/react-stripe-js';
import { removeItemAfterSignUp } from 'utils/soldOut';
import { SignUpPathEnum } from 'constants/signUpPath';
import { companyTypesEnum } from 'constants/companyTypes';
import { SIGN_UP_FLOW } from 'constants/availableButtons';
import { StepperSlider } from '../../InvitationLocation/styles';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Orders from 'assets/images/Orders.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Signup = () => {
  const history = useHistory();
  const isInSuccess = history.location.pathname.includes('success');
  const [active, setActive] = useState(SIGN_UP_FLOW.SIGN_UP_ACCOUNT_INFO);
  const [availableSteps, setAvailableSteps] = useState(SIGN_UP_FLOW.SIGN_UP_LEGAL_INFO);
  const [companyType, setCompanyType] = useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const planId = searchParams.get('plan');
  const corporateBusiness = searchParams.get('corporateBusiness');

  useEffect(() => {
    const accountInfoData = localStorage.getItem('accountInfo');
    if (accountInfoData) {
      const accountInfo = JSON.parse(accountInfoData);
      setCompanyType(accountInfo.companyType);
    }
  }, []);

  useEffect(() => {
    switch (history.location.pathname) {
      case SignUpPathEnum.ACCOUNT_INFO || `${SignUpPathEnum.ACCOUNT_INFO}/`:
        history.push({
          pathname: history.location.pathname,
          search: history.location.search,
        });
        setActive(SIGN_UP_FLOW.SIGN_UP_ACCOUNT_INFO);
        break;
      case SignUpPathEnum.LEGAL_INFO || `${SignUpPathEnum.LEGAL_INFO}/`:
        history.push({
          pathname: history.location.pathname,
          search: history.location.search,
        });
        setActive(SIGN_UP_FLOW.SIGN_UP_LEGAL_INFO);
        break;
      case SignUpPathEnum.BILLING_INFO || `${SignUpPathEnum.BILLING_INFO}/`:
        history.push({
          pathname: history.location.pathname,
          search: history.location.search,
        });
        setActive(SIGN_UP_FLOW.SIGN_UP_BILLING_INFO);
        break;
      default:
        history.push(history.location.pathname);
        setActive(SIGN_UP_FLOW.SIGN_UP);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    companyType !== companyTypesEnum.SINGLE
      ? setAvailableSteps(SIGN_UP_FLOW.SIGN_UP_LEGAL_INFO)
      : setAvailableSteps(SIGN_UP_FLOW.SIGN_UP_BILLING_INFO);
  }, [companyType]);
  const handleCheckSteps = () => {
    return (
      (companyType !== companyTypesEnum.SINGLE && history.location.pathname === SignUpPathEnum.SIGN_UP_SUCCESS) ||
      (companyType === companyTypesEnum.SINGLE && history.location.pathname === SignUpPathEnum.SIGN_UP_SUCCESS)
    );
  };

  useEffect(() => {
    return () => {
      removeItemAfterSignUp();
    };
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <Grid container spacing={2} sx={{ maxWidth: '800px' }}>
        <Grid item xs={12}>
          {!isInSuccess ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mb: 2 }}>
              <img src={Orders} alt="Orders" />
            </Box>
          ) : (
            ''
          )}
          <Box spacing={2} sx={{ display: handleCheckSteps() ? 'none' : '' }}>
            <SignUpTitle>{t('sign_up.welcome_to_Orders.co')} </SignUpTitle>
            <Typography align="center" sx={{ color: '#000000' }}>
              {t('sign_up.you_are_just')} {availableSteps?.length} {t('sign_up.steps_away_to_start')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StepperSlider
            sx={{ display: !!handleCheckSteps() ? 'none' : 'block' }}
            value={active}
            step={1}
            min={1}
            max={availableSteps}
            aria-label="Default"
            valueLabelDisplay="off"
          />
        </Grid>
        <Box sx={{ mt: 4, margin: '0 auto' }}>
          <Switch>
            <Route
              exact
              path={SignUpPathEnum.ACCOUNT_INFO}
              render={() => (
                <AccountInfo
                  setCompanyType={setCompanyType}
                  active={active}
                  steps={availableSteps}
                  externalPlanId={planId}
                  externalToken={token}
                  corporateBusiness={corporateBusiness}
                />
              )}
            />
            <Route
              exact
              path={SignUpPathEnum.LEGAL_INFO}
              render={() => (
                <LegaInfo
                  setCompanyType={setCompanyType}
                  active={active}
                  companyType={companyType}
                  externalPlanId={planId}
                  externalToken={token}
                  corporateBusiness={corporateBusiness}
                />
              )}
            />
            <Route
              exact
              path={SignUpPathEnum.BILLING_INFO}
              component={() => (
                <BillingInfo
                  active={active}
                  steps={availableSteps}
                  externalPlanId={planId}
                  externalToken={token}
                  corporateBusiness={corporateBusiness}
                />
              )}
            />
            <Route exact path={SignUpPathEnum.SIGN_UP_SUCCESS} component={Success} />
            <Redirect to={SignUpPathEnum.ACCOUNT_INFO} />
          </Switch>
        </Box>
      </Grid>
    </Elements>
  );
};

export default Signup;
