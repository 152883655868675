import React, { memo } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isAsapSwitcherTurnOff } from 'pages/config';
import { SaveButtonFooter } from 'redesign/Shared/styles';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import CustomTextInput from 'components/Forms/CustomTextInput';
import usePrepTimeAllProviders from 'pages/Providers/ProviderPrepTime/hooks/usePrepTimeAllProviders';

const PrepTimeAllProviders = ({ providerPrepTime, srv }) => {
  const labelProps = { style: { pointerEvents: 'auto' } };
  const { t } = useTranslation();
  const { prepTimeMethod, handleValidSubmit, loading, handleChange, hasDirty } = usePrepTimeAllProviders(
    providerPrepTime,
    srv
  );

  return (
    <FormProvider {...prepTimeMethod}>
      <form onSubmit={prepTimeMethod.handleSubmit(handleValidSubmit)}>
        <Grid container sx={{ pt: 3, pl: 2 }}>
          <Grid item xs={6}>
            <CustomTextInput
              sx={{ width: '100%' }}
              inputProps={{
                type: 'number',
              }}
              onChange={(event) => handleChange(event)}
              select={true}
              name={'prepTime'}
              labelProps={{
                labelProps,
                shrink: true,
              }}
              label={<LabelTooltip required id="defaultPrepTimeTooltip" value={t('settings.preparation_time')} />}
            >
              {isAsapSwitcherTurnOff.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option?.name}
                </MenuItem>
              ))}
            </CustomTextInput>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, width: '100%', textAlign: 'right', pr: 2 }}>
            <SaveButtonFooter variant="contained" type="submit" loading={loading} disabled={loading || !hasDirty}>
              {t('Save')}
            </SaveButtonFooter>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
export default memo(PrepTimeAllProviders);
