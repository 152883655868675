import React, { memo } from 'react';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CustomSwitch from 'components/Forms/CustomSwitch';
import { SwitcherWrapper } from 'pages/TableOrdering/styles';
import useDeliveryAutoConfirm from 'pages/Providers/TableOrdering/hooks/useDeliveryAutoConfirm';

const DeliveryAutoConfirm = ({ providerName, items, srv }) => {
  const { t } = useTranslation();
  const { autoConfirmDelivery, handleSubmit, handleConfirm, loading } = useDeliveryAutoConfirm(items, srv);

  return (
    <FormProvider {...autoConfirmDelivery}>
      <form
        onSubmit={autoConfirmDelivery.handleSubmit(handleSubmit)}
        style={{
          width: '100%',
          mt: 3,
        }}
      >
        {loading && <Preloader overlay={true} />}
        <SwitcherWrapper sx={{ ml: 2, mt: 2 }}>
          <CustomSwitch
            name="orderConfirm.delivery.autoConfirm"
            checked={autoConfirmDelivery.getValues()?.orderConfirm?.delivery?.autoConfirm}
            label={t(`Auto-confirm ${providerName} order`)}
            onChange={(event) => handleConfirm(event)}
          />
        </SwitcherWrapper>
      </form>
    </FormProvider>
  );
};

export default memo(DeliveryAutoConfirm);
