import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { PauseResumeBackButton } from './styles';
import {
  updateStopResumeOrders,
  resumeProvider,
  stopProvider,
  busyProvider,
  pinCodeCheck,
} from 'redux/actions/providers';
import { LoadingButton } from '@mui/lab';
import Preloader from 'components/Preloader';
import { useLocation } from 'react-router-dom';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import { PauseResumeContext } from 'context/pauseResumeContext';
import PinCode from 'components/PauseResume/VerificationCodeModal';
import PauseResumeModal from 'components/PauseResume/PauseResumeModal';
import ApplyAllBusiness from 'components/PauseResume/ApplyAllBusiness';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Divider } from '@mui/material';

const PauseResumeProviderIndex = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { setCheckedProvider, setSelectAll } = useContext(PauseResumeContext);
  const dispatch = useDispatch();
  const pinRef = useRef();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { stopResumeOrders } = useSelector(({ providers }) => providers);
  const [forAllBusinesses, setForAllBusinesses] = useState(false);
  const {
    data,
    showModal,
    loading,
    statuses: { status },
  } = stopResumeOrders;
  const [pinCodeSubmitted, setVerifyCodeSubmitted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const handleClose = () => {
    setCheckedProvider([]);
    setSelectAll(false);
    dispatch(
      updateStopResumeOrders({
        showModal: false,
      })
    );
    setCurrentStep(0);
    dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
    setForAllBusinesses(false);
  };

  useEffect(() => {
    if (showModal) {
      setCurrentStep(0);
      setForAllBusinesses(false);
    }
  }, [showModal]);

  const hasPausedProvider = useMemo(() => {
    return stopResumeOrders?.statuses?.details?.find((item) => item?.provider === location?.state?.data?.srv);
  }, [stopResumeOrders, location?.state?.data?.srv]);

  const handleStartStop = () => {
    const srv = location?.state?.data?.srv;
    if (data.action === PAUSE_RESUME_STATUSES.PAUSED) {
      return dispatch(stopProvider(forAllBusinesses, srv));
    } else if (data.action === PAUSE_RESUME_STATUSES.BUSY) {
      return dispatch(busyProvider(forAllBusinesses, srv));
    }
    return dispatch(resumeProvider(forAllBusinesses, srv));
  };

  const handleConfirm = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleCloseStep = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const handleSubmit = () => handleStartStop();

  const onCompleteCode = (value) => {
    dispatch(pinCodeCheck(value))
      .then(() => {
        setVerifyCodeSubmitted(true);
      })
      .catch(() => {
        pinRef?.current?.clear();
      });
  };

  useEffect(() => {
    setVerifyCodeSubmitted(!currentBusiness?.settings?.pinCode?.enable);
  }, [currentBusiness?.settings?.pinCode?.enable, showModal]);

  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ fontSize: '38px', color: '#000000', opacity: '80%' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '764px', height: '670px', maxWidth: '100%', background: '#FAFAFA' },
      }}
    >
      {loading && <Preloader />}
      <CustomDialogTitle id="alert-dialog-title" onClose={handleClose} sx={{ fontSize: '24px', fontWeight: '550' }}>
        {pinCodeSubmitted ? t('orders.store_status') : t('store_pin_code.title')}
      </CustomDialogTitle>
      <Divider />
      <DialogContent sx={{ width: '100%' }}>
        {currentStep === 0 && (
          <>
            {pinCodeSubmitted ? (
              <PauseResumeModal hasPausedProvider={hasPausedProvider} />
            ) : (
              <PinCode pinRef={pinRef} onCompleteCode={onCompleteCode} />
            )}
          </>
        )}
        {currentStep === 1 && (
          <ApplyAllBusiness status={data?.action} duration={data.duration} setForAllBusinesses={setForAllBusinesses} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', p: 2, cursor: 'grab' }}>
        {pinCodeSubmitted && (
          <>
            <Button
              id="pauseResumeCancel"
              sx={{ width: '192px', height: '54px', borderRadius: '6px' }}
              variant="contained"
              color="error"
              onClick={handleClose}
            >
              {t('orders.cancel')}
            </Button>
            <Box>
              {currentStep !== 0 && (
                <PauseResumeBackButton variant="outlined" onClick={handleCloseStep}>
                  {t('sign_up.back')}
                </PauseResumeBackButton>
              )}
              <LoadingButton
                sx={{ width: currentStep === 0 ? '300px' : '192px', height: '54px', borderRadius: '6px' }}
                variant="contained"
                loading={loading}
                disabled={
                  data.action === PAUSE_RESUME_STATUSES.BUSY &&
                  hasPausedProvider?.provider_status === PAUSE_RESUME_STATUSES.PAUSED
                }
                onClick={currentStep !== 1 ? handleConfirm : handleSubmit}
              >
                {currentStep !== 1 ? t('settings.continue') : t('settings.save')}
              </LoadingButton>
            </Box>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default PauseResumeProviderIndex;
