import React from 'react';
import { Button, Box } from '@mui/material';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { HeaderContainer, StripedDataGrid } from '../styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MODE, MUI_TO_SX_STYLES } from 'pages/TableOrdering/config';
import TableOrderModal from 'pages/TableOrdering/Tables/TableOrderModal';
import CreateOrEditForm from 'pages/TableOrdering/Jobs/CreateOrEditForm';
import { useTableOrderingJobs } from 'pages/TableOrdering/hooks/useTableOrderingJobs';

const Jobs = () => {
  const { t } = useTranslation();
  const {
    jobsFilter,
    jobColumns,
    setOpen,
    open,
    mode,
    formMethods,
    handleSubmit,
    handleCancel,
    currentPayBasis,
    handleChangePayBasis,
    currentTipped,
    handleChangeTipped,
    currentCashier,
    handleChangeCashier,
    currentDriver,
    handleChangeDriver,
    lastCreatedJob,
    setRowsPerPage,
    rowsPerPage,
    loading,
  } = useTableOrderingJobs();

  return (
    <Box>
      <HeaderContainer>
        <Button
          id="tablesAddTable"
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
          type="button"
          className={`${open ? 'disabledButton' : ''}`}
          startIcon={<AddOutlinedIcon />}
        >
          {t('table_ordering.new_jobs')}
        </Button>
      </HeaderContainer>
      <TableOrderModal
        open={open}
        handleClose={handleCancel}
        title={mode === MODE.CREAT ? t('table_ordering.new_jobs') : t('table_ordering.edit_jobs')}
        disabled={!formMethods.formState.isDirty || !formMethods.formState.isValid}
        saveTitle={mode === MODE.CREAT ? t('table_ordering.new_jobs') : t('table_ordering.save')}
        cancelTitle={'Cancel'}
        formMethods={formMethods}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <CreateOrEditForm
          currentPayBasis={currentPayBasis}
          handleChangePayBasis={handleChangePayBasis}
          currentTipped={currentTipped}
          handleChangeTipped={handleChangeTipped}
          currentCashier={currentCashier}
          handleChangeCashier={handleChangeCashier}
          currentDriver={currentDriver}
          handleChangeDriver={handleChangeDriver}
        />
      </TableOrderModal>
      <StripedDataGrid
        sx={MUI_TO_SX_STYLES}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        isEditable={false}
        hideFooter={true}
        getRowClassName={(params) => (params?.row?.id === lastCreatedJob ? 'highlight_last-selected_item' : '')}
        rowsPerPageOptions={[100]}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        rows={jobsFilter}
        columns={jobColumns}
        components={{
          LoadingOverlay: Preloader,
        }}
        loading={loading}
        localeText={{ noRowsLabel: t('No jobs to display') }}
      />
    </Box>
  );
};
export default Jobs;
