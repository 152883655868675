import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Box, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import { updateStopResumeOrders } from 'redux/actions/providers';
import useCountDownForProvider from './useCountDownForProvider';

function ResumePauseButtonForProvider({ provider, disabled }) {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(true);
  const { stopResumeOrders } = useSelector(({ providers }) => providers);
  const { isBrandLoading } = useSelector(({ business }) => business);
  const { statuses } = stopResumeOrders;
  const currentProviderStatuses = statuses?.details?.find((item) => item?.provider === provider);

  const { duration } = useCountDownForProvider(currentProviderStatuses, () => {
    return dispatch(
      updateStopResumeOrders({
        ...stopResumeOrders,
        data: {
          ...stopResumeOrders.data,
          action: PAUSE_RESUME_STATUSES.RECEIVING,
        },
        statuses: {
          ...stopResumeOrders.statuses,
          paused_until: '',
          status: PAUSE_RESUME_STATUSES.RECEIVING,
        },
      })
    );
  });

  useEffect(() => {
    if (!showSpinner) {
      setShowSpinner(true);
    }
  }, [stopResumeOrders.loading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 1200);
    return () => clearTimeout(timer);
  }, [stopResumeOrders]);

  const iconStyle = useMemo(() => {
    if (currentProviderStatuses?.provider_status === PAUSE_RESUME_STATUSES.RECEIVING)
      return { backgroundColor: '#11AF22' };
    if (
      currentProviderStatuses?.provider_status === PAUSE_RESUME_STATUSES.PARTIAL ||
      currentProviderStatuses?.provider_status === PAUSE_RESUME_STATUSES.BUSY
    )
      return {
        backgroundColor: '#FF8A00',
      };
    return {
      backgroundColor: 'red',
    };
  }, [currentProviderStatuses?.provider_status]);

  const handleShowResumePause = () => {
    if (!duration) return;
    dispatch(updateStopResumeOrders({ showModal: true, provider: provider }));
  };

  return (
    <>
      <Tooltip title={disabled ? 'Provider does not support status updates' : ''} placement="top">
        <span>
          <LoadingButton
            id="resumePauseButton"
            size="small"
            className="mr-2"
            onClick={handleShowResumePause}
            variant="outlined"
            loading={stopResumeOrders?.loading || isBrandLoading || showSpinner}
            startIcon={
              !showSpinner &&
              !isBrandLoading &&
              !stopResumeOrders?.loading && (
                <Box sx={{ ...iconStyle, mr: 1, width: 12, height: 12, borderRadius: 90 }} />
              )
            }
            sx={{
              color: 'black',
              textTransform: 'none',
              mb: 2,
              height: '40px',
              width: '240px',
              borderColor: 'rgba(0, 0, 0, 0.23)',
            }}
            disabled={disabled}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>{duration?.toUpperCase()}</Typography>
          </LoadingButton>
        </span>
      </Tooltip>
    </>
  );
}
export default ResumePauseButtonForProvider;
