import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomSwitch = ({ name, id, sx, label, inputProps, checked, onChange, ...params }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const handleChange = (event) => {
          field.onChange(event);
          if (onChange) onChange(event);
        };
        return (
          <FormControlLabel
            sx={sx}
            control={<Switch {...field} onChange={handleChange} checked={field.value} {...params} />}
            label={typeof label !== 'object' ? t(label) : label}
          />
        );
      }}
    />
  );
};

export default CustomSwitch;
